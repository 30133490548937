import { useMutation } from '@tanstack/react-query'
import { useGlobalPageState } from '@kaliber/use-global-page-state'

export function useCookieConfiguration({
  availablePermissions,
  cookieEndpoint,
  initialConfiguration,
  onChange = undefined,
  onError
}) {
  const [configuration, setConfiguration] = useGlobalPageState('@kaliber/cookie-consent')

  const mutation = useMutation({
    mutationFn: persistConfiguration,
    onSuccess: handleSuccess,
    onError: (e) => {
      onError(e)
      setConfiguration((x) => ({...x, show: false })) // Hide popup when a error occurs. Next page ask again.
    }
  })

  React.useEffect(
    () => {
      if (!initialConfiguration) return
      setConfiguration(initialConfiguration)
    },
    [initialConfiguration]
  )

  return {
    configuration,
    state: extractState(mutation),
    acceptAll: React.useCallback(acceptAll, [availablePermissions, mutation]),
    rejectAll: React.useCallback(rejectAll, [availablePermissions, mutation]),
    updatePermission: React.useCallback(updatePermission, [availablePermissions, mutation]),
    storeSelection: React.useCallback(storeSelection, [configuration, mutation]),
  }

  function acceptAll() {
    mutation.mutate({
      permissions: availablePermissions.reduce((result, x) => ({
        ...result,
        [x.name]: 'granted'
      }), {})
    })
  }

  function rejectAll() {
    mutation.mutate({
      permissions: availablePermissions.reduce((result, x) => ({
        ...result,
        [x.name]: (x.required || x.classification === 'functional') ? 'granted' : 'denied'
      }), {})
    })
  }

  function updatePermission(permission, allow) {
    mutation.mutate({
      permissions: { ...configuration?.permissions ?? {}, [permission]: allow ? 'granted' : 'denied' }
    })
  }

  function storeSelection({ permissions }) {
    mutation.mutate({
      permissions
    })
  }

  async function persistConfiguration({ permissions }) {
    try {
      const response = await fetch(cookieEndpoint, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ permissions })
      })

      return handleResponse(response)
    } catch (e) {
      throw e
    }
  }

  function handleSuccess(response) {
    onChange && onChange(response.configuration)
    setConfiguration(response.configuration)
  }
}

function extractState(state) {
  const { isError, isIdle, isPending, isPaused, isSuccess, status } = state

  return {
    isError,
    isIdle,
    isPending,
    isPaused,
    isSuccess,
    status,
  }
}

async function handleResponse(response) {
  const text = await response.text()
  if (!response.ok)
    throw new Error(`Unexpected response ${response.status}: ${text}`)

  if (text === '')
    return null

  try {
    const data = JSON.parse(text)
    if (data.error) throw new Error(data.error)
    return data
  } catch (e) {
    throw new Error(`Invalid response ${response.status}: ${text}\n${e.stack || e.message}`)
  }
}
